<template>
  <div class="content-wrapper">
    <page-header
      screen-name="Ver usuários"
      :link-items="linkItems"
    />

    <overlay
      :show="loading"
    >
      <div class="card p-2">
        <users-links class-name="mb-4" />

        <b-row class="mb-2">
          <b-col>
            <b-link
              type="button"
              class="btn custom-blue btn-add"
              :to="{ name: 'usuario-register' }"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Adicionar novo usuário SESI
            </b-link>
          </b-col>
        </b-row>

        <Filters
          :disable-button-search="table.tableBusy"
          :disable-button-clear="disableButtonClear || table.tableBusy"
          @actionSearch="handleSubmitFormFilters"
          @actionClear="clearFilters"
        >
          <validation-observer ref="formFilters">
            <b-form>
              <b-row class="align-items-center">
                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Nome"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nome"
                    >
                      <b-form-input
                        id="name"
                        v-model="search.name"
                        placeholder="Nome do usuário"
                        autocomplete="off"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="E-mail"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="E-mail"
                      rules="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="search.email"
                        placeholder="E-mail"
                        autocomplete="off"
                        type="email"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Perfil"
                    label-for="profile"
                  >
                    <v-select
                      id="profiles"
                      v-model="search.profile"
                      :options="profiles"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_perfil"
                      placeholder="Selecione o perfil"
                      label="descricao"
                    >
                      <template
                        v-slot:no-options
                      >
                        Nenhum registro encontrado.
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Departamento"
                    label-for="department"
                  >
                    <v-select
                      id="department"
                      v-model="search.department"
                      :options="departments"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_departamento"
                      placeholder="Selecione um departamento"
                      label="descricao"
                    >
                      <template
                        v-slot:no-options
                      >
                        Nenhum registro encontrado.
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Status"
                    label-for="active"
                  >
                    <v-select
                      id="active"
                      v-model="search.active"
                      :options="statusForm"
                      variant="custom"
                      item-text="description"
                      item-value="id"
                      placeholder="Selecione o status"
                      label="description"
                    >
                      <template
                        v-slot:no-options
                      >
                        Nenhum registro encontrado.
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Vacinador"
                    label-for="existsPessoaFisicaDocumento"
                  >
                    <v-select
                      id="existsPessoaFisicaDocumento"
                      v-model="search.existsPessoaFisicaDocumento"
                      :options="existsPessoaFisicaDocumentoForm"
                      variant="custom"
                      item-text="text"
                      item-value="value"
                      placeholder="Selecione se é vacinador"
                      label="text"
                    >
                      <template
                        v-slot:no-options
                      >
                        Nenhum registro encontrado.
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </Filters>

        <b-row>
          <b-col
            class="px-3"
            sm="6"
          >
            <div
              v-if="showTable"
              class="d-flex justify-center-center align-items-center"
            >
              <span class="mr-50">Mostrar</span>
              <v-select
                id="orders"
                v-model="paginationData.defaultSize"
                :options="table.tableRows"
                :clearable="false"
                @input="updateQtdView($event)"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12">
            <div
              v-if="table.empty"
              class="mx-2 mt-5"
            >
              <p class="table-empty">
                Não há dados a serem exibidos.
              </p>
            </div>

            <b-alert
              variant="primary"
              :show="table.tableError"
              class="mx-2 mt-5"
            >
              <div class="alert-body d-flex justify-content-center">
                <span class="text-primary">
                  <strong
                    class="text-primary"
                  >Sistema de busca indisponível no momento.</strong>
                </span>
              </div>
            </b-alert>
          </b-col>

          <b-col
            v-if="loadingTable"
            cols="12"
          >
            <div
              v-if="table.tableBusy"
              class="spinner-area"
            >
              <b-spinner class="spinner-border text-custom-blue" />
            </div>
          </b-col>

          <b-col
            v-if="showTable"
            cols="12"
            class="my-2"
          >
            <b-table
              responsive
              sticky-header="380px"
              :busy.sync="table.tableBusy"
              :no-local-sorting="true"
              :fields="table.fields"
              :items="table.items"
              @context-changed="handleOrderTable"
            >
              <template #cell(nome)="row">
                <div class="d-flex">
                  <b-img
                    v-if="row.item.tipo_atuacao.length > 0"
                    v-b-tooltip.hover.top="'Base Nacional'"
                    :src="nationalBaseIcon"
                  />

                  <div
                    v-if="row.item.tipo_atuacao.length === 0 || !row.item.tipo_atuacao"
                    class="margin-box"
                  />

                  <span
                    class="national-base-icon-margin"
                  >
                    {{ row.value }}
                  </span>
                </div>
              </template>

              <template #cell(email)="row">
                <span>{{ row.value }}</span>
              </template>

              <template #cell(descricao)="row">
                <span>{{ row.item.departamento_nome }}</span>
              </template>

              <template #cell(ativo)="row">
                <b-form-checkbox
                  v-model="row.value"
                  class="custom-control-success"
                  name="check-button"
                  :disabled="switchDisabled || !canEditUser(row.item)"
                  switch
                  @change="defineStatusUser(row)"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </template>

              <template #cell(actions)="row">
                <button-icon
                  color="#2772C0"
                  size="18"
                  class-name="m-button-icon"
                  feather-icon="EyeIcon"
                  @action="redirectToView(row.item)"
                />
                <button-icon v-if="canEditUser(row.item)"
                  color="#2772C0"
                  size="18"
                  feather-icon="EditIcon"
                  @action="redirectToEdit(row.item)"
                />
              </template>
            </b-table>
          </b-col>

          <b-col
            v-if="showTable"
            class="px-3"
            sm="12"
          >
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </b-row>
      </div>
    </overlay>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BTable,
  BAlert,
  BLink,
  BImg,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import { statusForm } from '@core/utils/status-form'
import { actions, subjects } from '@/libs/acl/rules'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ButtonIcon from '@/views/components/custom/Buttons/ButtonIcon/ButtonIcon.vue'
import UsersLinks from '@/views/custom-pages/gerenciarUsuarios/components/UsersLinks.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import {
  getAdminSesiUsers,
  getDepartments,
  getProfiles,
  updateStatusAdminSesiUser,
} from '@/views/custom-pages/gerenciarUsuarios/requests'
import { warningMessage } from '@/libs/sweetalerts'
import nationalBaseIcon from '@/assets/custom-icons/pages/national.svg'
import Filters from '@/views/components/custom/filters/Filters.vue'

export default {
  title: 'Gerenciar usuários',

  components: {
    Filters,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BTable,
    BAlert,
    BLink,
    BSpinner,
    BImg,
    BFormCheckbox,
    Overlay,
    UsersLinks,
    ButtonIcon,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    PageHeader,
    CustomPagination,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Gerenciar usuários',
          active: true,
          routeName: 'usuario-list',
        },
      ],

      nationalBaseIcon,

      loading: true,
      loadingTable: false,

      required,
      email,
      titlePage: '',

      search: {
        name: '',
        email: '',
        profile: null,
        department: null,
        active: null,
        existsPessoaFisicaDocumento: null,
      },

      showTable: false,
      switchDisabled: false,

      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },

      profiles: [],
      departments: [],

      statusForm,

      table: {
        empty: false,
        tableError: false,
        tableBusy: true,
        tableRows: [10, 25, 50, 100],
        tableOrder: '',
        tableColumnNameOrder: '',
        fields: [
          { key: 'nome', label: 'NOME', sortable: true },
          { key: 'email', label: 'E-MAIL', sortable: true },
          { key: 'descricao', label: 'DEPARTAMENTO', sortable: true },
          { key: 'ativo', label: 'STATUS', sortable: true },
          {
            key: 'actions',
            label: 'AÇÕES',
            class: 'width-custom-column text-center',
          },
        ],
        items: [],
      },

      existsPessoaFisicaDocumentoForm: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ]
    }
  },

  computed: {
    disableButtonClear() {
      return [
        this.search.name === '',
        this.search.email === '',
        this.search.profile === null,
        this.search.department === null,
        this.search.active === null,
        this.search.existsPessoaFisicaDocumento === null,
      ].every(field => field === true)
    },
  },

  mounted() {
    this.canManage = this.$can(actions.GERENCIAR, subjects.USUARIO_SESI_MENUS_ROTAS)

    this.index()
  },

  methods: {
    async index() {
      this.loading = true

      await getProfiles()
        .then(response => {
          const profiles = response.data

          this.profiles = profiles.filter(profile => profile.id_tipo_perfil === 1 || profile.id_tipo_perfil === 2)
        })

      await getDepartments()
        .then(response => {
          this.departments = response.data
        })

      this.loading = false

      this.loadingTable = true

      this.findAll()
    },

    findAll() {
      this.table.tableBusy = true

      this.table.tableError = false
      this.table.empty = false

      getAdminSesiUsers(this.getParams())
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.showTable = true
              this.table.items = response.data.data
              this.table.tableBusy = false
              this.handlePagination(response.data)
              return
            }

            this.table.empty = true
            this.showTable = false
            this.table.tableBusy = false
          }
        })
        .catch(() => {
          this.table.tableError = true
          this.table.tableBusy = false
          this.showTable = false
        })
    },

    async defineStatusUser({ item }) {
      this.switchDisabled = true

      await updateStatusAdminSesiUser(item.id_usuario)
        .catch(() => {
          warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
        })

      this.switchDisabled = false
    },

    redirectToView(row) {
      this.$router.push(
        {
          name: 'usuario-view',
          params: {
            idUsuario: row.id_usuario,
          },
        },
      )
    },

    redirectToEdit(row) {
      this.$store.commit('adminUserState/setAdminUserUpdate', row)

      this.$router.replace({ name: 'usuario-edit' })
    },

    handleSubmitFormFilters() {
      this.paginationData.currentPage = 1
      this.$refs.formFilters.validate()
        .then(success => {
          if (success) {
            this.findAll()
          }
        })
    },

    clearFilters() {
      this.search.name = ''
      this.search.email = ''
      this.search.profile = null
      this.search.department = null
      this.search.active = null
      this.search.existsPessoaFisicaDocumento = null

      this.findAll()
    },

    handleOrderTable(context) {
      this.table.tableColumnNameOrder = context.sortBy
      this.table.tableOrder = context.sortDesc ? 'desc' : 'asc'

      this.findAll()
    },

    getParams() {
      let active = null
      
      if (this.search.active) {
        active = this.search.active.boolValue ? 1 : 0
      }
      
      let existsPessoaFisicaDocumento = null;
      if (this.search.existsPessoaFisicaDocumento) {
        existsPessoaFisicaDocumento = this.search.existsPessoaFisicaDocumento.value ? 1 : 0
      }

      return {
        colunaNome: this.table.tableColumnNameOrder,
        colunaOrdem: this.table.tableOrder,
        porPagina: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        nome: this.search.name,
        email: this.search.email,
        perfisId: this.search.profile ? [this.search.profile.id_perfil] : [],
        departamentoId: this.search.department ? this.search.department.id_departamento : null,
        ativo: active,
        existsPessoaFisicaDocumento: existsPessoaFisicaDocumento,
      }
    },

    handlePagination(data) {
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
      this.paginationData.totalLines = data.total
      this.paginationData.currentPage = data.current_page
    },

    updateQtdView(event) {
      if (!event) {
        this.paginationData.defaultSize = 10
      }

      this.paginationData.currentPage = 1
      this.findAll()
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.findAll()
    },
    canEditUser(user) {
      return !('pode_editar_usuario' in user) || Boolean(user['pode_editar_usuario']);
    }
  },
}
</script>

<style lang="scss" scoped>
.national-base-icon-margin {
  margin-left: 12px;
}

.m-button-icon {
  margin-right: 10px;
}

.margin-box {
  width: 20px;
  height: 22px;
}

@media (max-width: 400px) {
  .btn-add {
    width: 100%;
  }
}
</style>
